import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSessionDataQuery } from '@/api/sessionApi';
import {
  Business,
  selectedBusinessSelector,
  setSelectedBusiness,
} from '@/api/store/slices/businessSlice';
import { useRouter } from 'next/router';

export const useDefaultBusiness = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { isLoading, data: sessionData } = useGetSessionDataQuery('');
  const selectedBusiness = useSelector(selectedBusinessSelector);

  useEffect(() => {
    if (sessionData?.data?.businesses?.length > 0) {
      const businesses = sessionData.data.businesses;
      const businessIdQueryParam = router.query.businessId;
      let defaultBusiness;

      if (businessIdQueryParam) {
        defaultBusiness = businesses.find(
          (business: Business) => business.id === businessIdQueryParam,
        );
      }

      if (!defaultBusiness) {
        defaultBusiness = businesses[0];
      }

      if (
        defaultBusiness &&
        (!selectedBusiness || selectedBusiness.id !== defaultBusiness.id)
      ) {
        dispatch(setSelectedBusiness(defaultBusiness));
      }
    }
  }, [sessionData, router.query.businessId, dispatch, selectedBusiness]);

  return { isLoading, sessionData };
};
