import styled from 'styled-components';

export const Outer = styled.div`
  font-family: ${({ theme }) => theme.font.fontFamily.beausiteClassic};
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.gray[200]} 0%, ${theme.colors.gray[200]} 50%, ${theme.colors.white} 50%, ${theme.colors.white} 100%)`};
  ${({ theme }) => theme.mediaQueries.tablet} {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1440px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: column;
    max-width: 100%;
  }
`;
