import * as React from "react";
import { useRouter } from "next/router";
import {
  BreadcrumbCurrent,
  BreadcrumbLink,
  BreadcrumbWrapper,
  useAuth,
} from "@seriesfi/ui";
import { Welcome } from "./styles";
import { capitalize } from "lodash";

interface BreadCrumbProps {
  nameOverride?: string;
}

export const Breadcrumbs = ({ nameOverride }: BreadCrumbProps) => {
  const { user } = useAuth();
  const router = useRouter();
  const breadcrumbs = React.useMemo<{ name: string; href: string }[]>(
    () =>
      router.pathname.length > 1
        ? router.pathname.split("/").map((segment, index, segments) => ({
            name: segment || "home",
            href: segments.slice(0, index + 1).join("/") || "/",
          }))
        : [
            {
              name: "home",
              href: "/",
            },
          ],
    [router.pathname]
  );

  return breadcrumbs.length > 1 ? (
    <BreadcrumbWrapper>
      {breadcrumbs.map((breadcrumb, index) => {
        const name = capitalize(breadcrumb.name);
        return index < breadcrumbs.length - 1 ? (
          <BreadcrumbLink href={breadcrumb.href} key={breadcrumb.href}>
            {name}
          </BreadcrumbLink>
        ) : (
          <BreadcrumbCurrent key={breadcrumb.href}>
            {nameOverride || name}
          </BreadcrumbCurrent>
        );
      })}
    </BreadcrumbWrapper>
  ) : (
    <Welcome>
      Welcome to Series Nucleus, <span>{user?.firstName}</span>
    </Welcome>
  );
};
