import * as React from "react";

import { Link } from "./styles";

const BLACK = "#1a1c1e";
const DARK_GREEN = "#044741";
const LIGHT_GREEN = "#57eadb";
const WHITE = "#ffffff";

export const Logo = () => {
  return (
    <Link href="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="42"
        viewBox="0 0 176 42"
        fill="none"
      >
        <g clipPath="url(#clip0_254_6944)">
          <path
            d="M20.9953 0C9.40453 0 0 9.40453 0 20.9953C0 32.586 9.40453 41.9906 20.9953 41.9906C32.586 41.9906 41.9906 32.586 41.9906 20.9953C41.9906 9.40453 32.5955 0 20.9953 0Z"
            fill="#F0B3FF"
          />
          <path
            d="M48.3984 11.4116C48.4079 12.3162 48.7471 13.0607 49.4067 13.6167C50.0852 14.1632 51.0181 14.4459 52.2149 14.4459C53.4117 14.4459 54.2504 14.1915 54.9194 13.6732C55.5979 13.1643 55.9277 12.4482 55.9277 11.5341C55.9277 10.0358 54.9194 9.18768 53.4211 8.89556L51.8191 8.57516C51.4705 8.5092 51.4328 8.48093 51.0935 8.36785C50.9239 8.30189 50.8014 8.2265 50.7072 8.16054C50.5564 8.00976 50.3585 7.72706 50.3585 7.37839C50.3585 6.62452 50.9522 6.1722 52.0076 6.1722C53.063 6.1722 53.798 6.66222 53.864 7.59513H55.5885C55.5791 6.76588 55.2587 6.09682 54.6273 5.59738C53.9959 5.09794 53.1572 4.84351 52.083 4.84351C51.0087 4.84351 50.1889 5.09794 49.5481 5.61622C48.9073 6.11566 48.5869 6.78472 48.5869 7.61398C48.5869 8.36785 48.9167 8.98037 49.2654 9.30076C49.4444 9.47038 49.6706 9.61174 49.9721 9.75309C50.2643 9.88501 50.4999 9.96982 50.6506 10.0075C50.8014 10.0452 51.0087 10.1018 51.2631 10.1489L52.8368 10.4787C53.1478 10.5447 53.2986 10.5823 53.619 10.7708C53.9677 10.9404 54.1279 11.2608 54.1279 11.7037C54.1279 12.533 53.4211 13.089 52.2338 13.089C50.9051 13.089 50.17 12.4859 50.1229 11.4022H48.3984V11.4116Z"
            fill="#1A1C1E"
          />
          <path
            d="M64.0884 14.3139V12.8439H59.3296V10.177H63.6455V8.79181H59.3296V6.44539H64.0884V4.97534H57.6616V14.3139H64.0884Z"
            fill="#1A1C1E"
          />
          <path
            d="M67.5562 14.314V10.8933H70.0157C71.024 10.8933 71.3633 11.2985 71.3633 12.2126V13.1832C71.3633 13.7298 71.4104 14.1161 71.5046 14.314H73.2197V14.248C73.1066 14.135 73.0783 13.7109 73.0783 13.2397V11.9299C73.0783 10.931 72.6543 10.1018 71.3538 10.1018V10.0358C72.6543 9.91329 73.2291 8.77306 73.2291 7.70822C73.2291 6.02144 72.1925 4.98486 70.289 4.98486H65.8977V14.314H67.5656H67.5562ZM67.5562 6.43606H69.8367C70.9109 6.43606 71.4481 6.92608 71.4481 7.89669C71.4481 8.8673 70.7979 9.4327 69.7048 9.4327H67.5562V6.43606Z"
            fill="#1A1C1E"
          />
          <path
            d="M76.8288 4.98486H75.1609V14.314H76.8288V4.98486Z"
            fill="#1A1C1E"
          />
          <path
            d="M85.5077 14.3139V12.8439H80.7489V10.177H85.0648V8.79181H80.7489V6.44539H85.5077V4.97534H79.0809V14.3139H85.5077Z"
            fill="#1A1C1E"
          />
          <path
            d="M86.5725 11.4116C86.5819 12.3162 86.9212 13.0607 87.5808 13.6167C88.2593 14.1632 89.1922 14.4459 90.389 14.4459C91.5857 14.4459 92.4244 14.1915 93.0935 13.6732C93.772 13.1643 94.1018 12.4482 94.1018 11.5341C94.1018 10.0358 93.0935 9.18768 91.5952 8.89556L89.9932 8.57516C89.6445 8.5092 89.6068 8.48093 89.2676 8.36785C89.098 8.30189 88.9755 8.2265 88.8812 8.16054C88.7305 8.00976 88.5326 7.72706 88.5326 7.37839C88.5326 6.62452 89.1168 6.1722 90.1817 6.1722C91.2465 6.1722 91.9721 6.66222 92.0381 7.59513H93.7625C93.7531 6.76588 93.4327 6.09682 92.8014 5.59738C92.17 5.09794 91.3313 4.84351 90.257 4.84351C89.1828 4.84351 88.3629 5.09794 87.7222 5.61622C87.0814 6.11566 86.761 6.78472 86.761 7.61398C86.761 8.36785 87.0908 8.98037 87.4395 9.30076C87.6185 9.47038 87.8447 9.61174 88.1462 9.75309C88.4383 9.88501 88.6739 9.96982 88.8247 10.0075C88.9755 10.0452 89.1828 10.1018 89.4372 10.1489L91.0109 10.4787C91.3219 10.5447 91.4727 10.5823 91.7931 10.7708C92.1417 10.9404 92.3019 11.2608 92.3019 11.7037C92.3019 12.533 91.5952 13.089 90.4078 13.089C89.0791 13.089 88.3441 12.4859 88.297 11.4022H86.5725V11.4116Z"
            fill="#1A1C1E"
          />
          <path
            d="M54.8912 26.2818V37.5993H50.0193V19.3462H55.8901L62.4393 30.6637V19.3462H67.3112V37.5993H61.4404L54.8912 26.2818Z"
            fill="#1A1C1E"
          />
          <path
            d="M70.355 30.268V19.3463H75.4907V29.9571C75.4907 32.3035 76.744 33.6039 78.6475 33.6039C80.5511 33.6039 81.8044 32.3035 81.8044 29.9759V19.3369H86.9401V30.2398C86.9401 35.1116 83.5194 37.9104 78.6193 37.9104C73.7191 37.9104 70.355 35.1682 70.355 30.268Z"
            fill="#1A1C1E"
          />
          <path
            d="M89.1074 28.3927C89.1074 22.3712 93.772 18.9788 98.4931 18.9788C103.214 18.9788 106.71 21.6927 107.2 25.839H102.065C101.669 24.2747 100.34 23.2852 98.4649 23.2852C96.1656 23.2852 94.3468 25.217 94.3468 28.3927C94.3468 31.5684 96.1938 33.6038 98.4931 33.6038C100.293 33.6038 101.593 32.6615 102.093 31.0218H107.229C106.654 35.1399 103.026 37.9292 98.4366 37.9292C93.5647 37.9292 89.098 34.4614 89.098 28.3833L89.1074 28.3927Z"
            fill="#1A1C1E"
          />
          <path
            d="M109.5 19.3462H114.635V33.3493H122.56V37.5993H109.5V19.3462Z"
            fill="#1A1C1E"
          />
          <path
            d="M138.571 37.5993H124.747V19.3462H138.411V23.3134H129.703V26.3949H137.685V30.2302H129.703V33.6415H138.571V37.6087V37.5993Z"
            fill="#1A1C1E"
          />
          <path
            d="M141.096 30.268V19.3463H146.232V29.9571C146.232 32.3035 147.485 33.6039 149.389 33.6039C151.292 33.6039 152.546 32.3035 152.546 29.9759V19.3369H157.681V30.2398C157.681 35.1116 154.27 37.9104 149.36 37.9104C144.451 37.9104 141.096 35.1682 141.096 30.268Z"
            fill="#1A1C1E"
          />
          <path
            d="M159.792 31.7851H164.824C165.032 33.4813 166.313 34.2352 168.028 34.2352C169.564 34.2352 170.657 33.6321 170.657 32.5107C170.657 31.5495 169.8 31.0501 168.028 30.6826L166.228 30.3151C162.054 29.4764 160.15 27.6294 160.15 24.7647C160.15 21.3252 163.486 18.9788 167.585 18.9788C171.685 18.9788 174.936 21.0425 175.331 24.7647H170.346C170.139 23.3795 169.065 22.6539 167.529 22.6539C166.172 22.6539 165.126 23.3324 165.126 24.3218C165.126 25.2076 165.804 25.5751 167.425 25.8861L169.385 26.2725C173.268 27.0263 175.699 28.6943 175.699 32.0396C175.699 35.7901 172.127 37.9292 167.981 37.9292C163.599 37.9292 160.188 35.9691 159.792 31.7757V31.7851Z"
            fill="#1A1C1E"
          />
          <path
            d="M20.9952 19.4499C21.8528 19.4499 22.5501 20.1472 22.5501 21.0048C22.5501 21.8623 21.8528 22.5596 20.9952 22.5596C20.1377 22.5596 19.4404 21.8623 19.4404 21.0048C19.4404 20.1472 20.1377 19.4499 20.9952 19.4499ZM20.9952 16.3496C18.4227 16.3496 16.3401 18.4322 16.3401 21.0048C16.3401 23.5773 18.4227 25.6599 20.9952 25.6599C23.5678 25.6599 25.6504 23.5773 25.6504 21.0048C25.6504 18.4322 23.5678 16.3496 20.9952 16.3496Z"
            fill="#590C6B"
          />
          <path
            d="M12.0901 22.5501C12.9489 22.5501 13.645 21.854 13.645 20.9953C13.645 20.1366 12.9489 19.4404 12.0901 19.4404C11.2314 19.4404 10.5353 20.1366 10.5353 20.9953C10.5353 21.854 11.2314 22.5501 12.0901 22.5501Z"
            fill="#590C6B"
          />
          <path
            d="M29.9097 22.5501C30.7684 22.5501 31.4646 21.854 31.4646 20.9953C31.4646 20.1366 30.7684 19.4404 29.9097 19.4404C29.051 19.4404 28.3549 20.1366 28.3549 20.9953C28.3549 21.854 29.051 22.5501 29.9097 22.5501Z"
            fill="#590C6B"
          />
          <path
            d="M20.9953 13.6451C21.854 13.6451 22.5501 12.949 22.5501 12.0903C22.5501 11.2315 21.854 10.5354 20.9953 10.5354C20.1366 10.5354 19.4404 11.2315 19.4404 12.0903C19.4404 12.949 20.1366 13.6451 20.9953 13.6451Z"
            fill="#590C6B"
          />
          <path
            d="M20.9953 31.4647C21.854 31.4647 22.5501 30.7686 22.5501 29.9098C22.5501 29.0511 21.854 28.355 20.9953 28.355C20.1366 28.355 19.4404 29.0511 19.4404 29.9098C19.4404 30.7686 20.1366 31.4647 20.9953 31.4647Z"
            fill="#590C6B"
          />
        </g>
        <defs>
          <clipPath id="clip0_254_6944">
            <rect width="175.699" height="42" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Link>
  );
};
