import React from "react";
import Modal from "react-modal";

interface ModalProps {
  isOpen: boolean;
  styleOverride?: object;
  onRequestClose: () => void;
}

const ConfirmationModal = (props: React.PropsWithChildren & ModalProps) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      style={props.styleOverride}
      onRequestClose={props.onRequestClose}
    >
      {props.children}
    </Modal>
  );
};

export default ConfirmationModal;
