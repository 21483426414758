import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  margin-right: 24px;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: ${({ theme }) => theme.colors.gray[200]};
    border: none;
    border-radius: 50%;
    transition: none;
    > svg {
      fill: none;
      > path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
    > span {
      display: none;
    }
  }
`;
