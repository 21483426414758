import * as React from "react";
import { useRouter } from "next/router";

import { Companies } from "./components";
import { Item, Items, Link, Wrapper } from "./styles";
import { isDev } from "@/components/apps/constants";

type Props = {};

const navigationOptions = [
  { href: "/", name: "Home" },

  { href: "/users", name: "Users" },

  {
    push: true,
    badge: null,
    href: "/settings",
    name: "Settings",
  },
];

export const Navigation = (props: Props) => {
  const router = useRouter();

  const query: {
    businessId?: string | string[];
    redirectUrl?: string | string[];
  } = {};

  if (router.query.businessId) {
    query.businessId = router.query.businessId;
  }

  if (router.query.redirectUrl) {
    query.redirectUrl = router.query.redirectUrl;
  }

  return (
    <Wrapper $open={false}>
      <Items>
        {navigationOptions.map((link, index) => (
          <Item key={link.name + index}>
            <Link
              href={{
                pathname: link.href,
                query,
              }}
              $active={
                link.href.length > 1
                  ? router.pathname.startsWith(link.href)
                  : router.pathname === link.href
              }
              data-testid={`navigation_link_${link.name}`}
            >
              {link.name}
            </Link>
          </Item>
        ))}
      </Items>
      <Link
        href={`${process.env.NEXT_PUBLIC_ACCESS_APP_BASE_URL}/logout`}
        data-testid={`navigation_link_logout`}
      >
        Logout
      </Link>
      <Companies />
    </Wrapper>
  );
};
