import NextLink from 'next/link';
import styled from 'styled-components';

export const Link = styled(NextLink)`
  display: inline-block;
  width: 220px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 160px;
  }
`;
