import * as React from "react";

import {
  PageContainer,
  PageHeader,
  Details,
  PageContent,
  Title,
  StyledInlineNotification,
} from "./styles";
import { Breadcrumbs } from "./components";

type Props = React.PropsWithChildren<
  NonNullable<AppPage["layoutConfig"]>["options"]
>;

export const Content = (props: Props) => {
  return (
    <PageContainer>
      <PageHeader>
        <Details>
          {/* <StyledInlineNotification>
            <div style={{ color: "white" }}>Go back to where you were...</div>
          </StyledInlineNotification> */}
          <Breadcrumbs nameOverride={props.breadCrumbOverride} />

          <Title>
            <h1 data-testid="page_title">{props.pageTitle}</h1>
            {props.actions}
          </Title>
        </Details>
      </PageHeader>
      <PageContent>{props.children}</PageContent>
    </PageContainer>
  );
};
