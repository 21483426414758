import { useRouter } from 'next/router';
import * as React from 'react';

import { Wrapper } from './styles';

type Props = {
  enabled?: boolean;
};

export const Back = (props: Props) => {
  const router = useRouter();

  return props.enabled ? (
    <Wrapper>
      <button onClick={router.back}>
        <svg width="17" height="16" viewBox="0 0 17 16">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.67049 14.7954C8.23116 15.2348 7.51884 15.2348 7.0795 14.7954L0.704501 8.42049C0.265166 7.98116 0.265166 7.26884 0.704501 6.8295L7.0795 0.454501C7.51884 0.0151662 8.23116 0.0151662 8.67049 0.454501C9.10983 0.893836 9.10983 1.60616 8.67049 2.04549L4.21599 6.5H15.375C15.9963 6.5 16.5 7.00368 16.5 7.625C16.5 8.24631 15.9963 8.75 15.375 8.75H4.21599L8.67049 13.2045C9.10983 13.6439 9.10983 14.3561 8.67049 14.7954Z"
          />
        </svg>
        <span>Go back</span>
      </button>
    </Wrapper>
  ) : null;
};
